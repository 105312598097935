export const Y2KDetail = [
	{
		id: 1,
		defalut: require('@/assets/img/Y2K系列素材/产品模特图/Y2KCY1.jpg'),
		active: require('@/assets/img/Y2K系列素材/产品模特图/Y2KCY2.jpg'),
		series: 'Y2K系列',
		seriesName: 'Y2K唇釉',
		zhName: '酵色「Y2K唇釉」',
		enName: 'Future Nostalgia Matte Lipgloss',
		price: '79',
		of: '3.2g',
		intro: '跳跃亮色 辣妹必备',
		imgUrl: [
			{ index: 1, url: require('@/assets/img/详情/Y2K素材/小图/4.jpg'), bigUrl: require('@/assets/img/详情/Y2K素材/大图/4.jpg') },
			{ index: 2, url: require('@/assets/img/详情/Y2K素材/小图/2.jpg'), bigUrl: require('@/assets/img/详情/Y2K素材/大图/2.jpg') },
			{ index: 3, url: require('@/assets/img/详情/Y2K素材/小图/3.jpg'), bigUrl: require('@/assets/img/详情/Y2K素材/大图/3.jpg') },
			{ index: 3, url: require('@/assets/img/详情/Y2K素材/小图/1.jpg'), bigUrl: require('@/assets/img/详情/Y2K素材/大图/1.jpg') },
		],
		color: [
			{
				active: '#b82f3c',
				name: '#V09 甜心辣妹',
				span1: '玫调火龙果色 亮眼辣妹',
				span2: ' ',
				color: require('@/assets/img/详情/Y2K素材/大图/09.jpg')
			},
			{
				active: '#7f212d',
				name: '#V10 蒸汽梅果',
				span1: '紫调梅果色 气场加倍',
				span2: ' ',
				color: require('@/assets/img/详情/Y2K素材/大图/10.jpg')
			},
		]
	},
	{
		id: 2,
		defalut: require('@/assets/img/Y2K系列素材/产品模特图/Y2KYYP1.jpg'),
		active: require('@/assets/img/Y2K系列素材/产品模特图/Y2KYYP2.jpg'),
		series: 'Y2K系列',
		seriesName: '千禧眼盘',
		zhName: '酵色「千禧眼盘」',
		enName: 'Future Nostalgia Eyeshadow Palette',
		intro: '电光金属外壳 高彩火龙果撞色',
		price: '149',
		of: '9g',
		imgUrl: [
			{ index: 1, url: require('@/assets/img/详情/Y2K素材/千禧眼盘/1.jpg'), bigUrl: require('@/assets/img/详情/Y2K素材/千禧眼盘/01.jpg') },
			{ index: 2, url: require('@/assets/img/详情/Y2K素材/千禧眼盘/2.jpg'), bigUrl: require('@/assets/img/详情/Y2K素材/千禧眼盘/02.jpg') },
			{ index: 3, url: require('@/assets/img/详情/Y2K素材/千禧眼盘/3.jpg'), bigUrl: require('@/assets/img/详情/Y2K素材/千禧眼盘/03.jpg') },
			{ index: 3, url: require('@/assets/img/详情/Y2K素材/千禧眼盘/4.jpg'), bigUrl: require('@/assets/img/详情/Y2K素材/千禧眼盘/04.jpg') },
		],
		color: [
			{
				active: '#c02b60',
				name: '#06 千禧粉盘',
				span1: '电光金属 高彩撞色',
				span2: ' ',
				color: require('@/assets/img/详情/Y2K素材/千禧眼盘/006.jpg')
			}
		]
	}
]
